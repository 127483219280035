export const environment = {
  production: true,
  customerAbbreviation: 'HCA',
  customerName: 'HCA',
  defaultPath: '/dashboard',
  baseApiUrl: 'https://apis-hca-dev-cu-001.azurewebsites.net',
  apiUrl: 'https://apis-hca-dev-cu-001.azurewebsites.net/api/',

  msalConfig: {
    auth: {
      clientId: 'f931f28f-a717-4f55-8cfd-2b3c8399b689',
      authority: 'https://lumatrakpulsedev.ciamlogin.com/',
      redirectUri: '/',
      postLogOutUri: '/logout'
    }
  },
  graphApiConfig: {
    scopes: ['User.Read'],
    root: 'https://graph.microsoft.com/v1.0',
    profile: '/me',
    profilePhoto: '/me/photo/$value'
  },
  msalProtectedEndpoints: [
    {
      scopes: ['api://b91c4b52-1eb1-4506-b95d-35b949a2aee8/Pharmacy.BasicRead'],
      root: 'https://apis-hca-dev-cu-001.azurewebsites.net'
    }
  ]
};
