export type PulseColor =
  | 'UnFilteredGridHeader'
  | 'FilteredGridHeader'
  | 'GreenStatus'
  | 'OrangeStatus'
  | 'RedStatus'
  | 'YellowStatus'
  | 'ReturningStatus'
  | 'ReturnedStatus'
  | 'RecalledStatus';

export type PulseColorTheme = {
  color: string;
  backgroundColor: string;
  fontStyle: string;
  fontWeight: string;
  fontVariant: string;
};

export const PulseThemeColors: Record<PulseColor, PulseColorTheme> = {
  UnFilteredGridHeader: {
    color: '#000000',
    backgroundColor: '#e3e3e3',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontVariant: 'normal'
  },
  FilteredGridHeader: {
    color: '#ffffff',
    backgroundColor: '#3B82F6',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontVariant: 'normal'
  },
  GreenStatus: {
    color: '#000000',
    backgroundColor: '#9CA777',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontVariant: 'normal'
  },
  OrangeStatus: {
    color: '#000000',
    backgroundColor: '#b1DDF1',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontVariant: 'normal'
  },
  RedStatus: {
    color: '#000000',
    backgroundColor: '#FF3352',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontVariant: 'normal'
  },
  YellowStatus: {
    color: '#000000',
    backgroundColor: '#EF8354',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontVariant: 'normal'
  },
  ReturningStatus: {
    color: '#000000',
    backgroundColor: '#E5E4E2',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontVariant: 'normal'
  },
  ReturnedStatus: {
    color: '#000000',
    backgroundColor: '#E5E4E2',
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontVariant: 'normal'
  },
  RecalledStatus: {
    color: '#000000',
    backgroundColor: '#FFE347',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontVariant: 'normal'
  }
};
