import { ExcelStyle } from '@ag-grid-community/core';
import { PulseThemeColors } from '@styling/PulseTheme';

export const defaultExcelExportStyles: ExcelStyle[] = [
  {
    id: 'unfilteredColumn',
    alignment: {
      vertical: 'Center'
    },
    font: {
      bold: true,
      size: 12,
      color: PulseThemeColors.UnFilteredGridHeader.color
    },
    interior: {
      /** This color needs to match the actual style of the header AND the color on the border elements below */
      color: PulseThemeColors.UnFilteredGridHeader.backgroundColor,
      pattern: 'Solid',
      patternColor: undefined
    },
    borders: {
      borderBottom: {
        color: PulseThemeColors.UnFilteredGridHeader.backgroundColor,
        lineStyle: 'Continuous',
        weight: 1
      },
      borderLeft: {
        color: PulseThemeColors.UnFilteredGridHeader.backgroundColor,
        lineStyle: 'Continuous',
        weight: 1
      },
      borderRight: {
        color: PulseThemeColors.UnFilteredGridHeader.backgroundColor,
        lineStyle: 'Continuous',
        weight: 1
      },
      borderTop: {
        color: PulseThemeColors.UnFilteredGridHeader.backgroundColor,
        lineStyle: 'Continuous',
        weight: 1
      }
    }
  },
  {
    id: 'header',
    alignment: {
      vertical: 'Center'
    },
    font: {
      bold: true,
      size: 12,
      color: PulseThemeColors.UnFilteredGridHeader.color
    },
    interior: {
      /** This color needs to match the actual style of the header AND the color on the border elements below */
      color: PulseThemeColors.UnFilteredGridHeader.backgroundColor,
      pattern: 'Solid',
      patternColor: undefined
    },
    borders: {
      borderBottom: {
        color: PulseThemeColors.UnFilteredGridHeader.backgroundColor,
        lineStyle: 'Continuous',
        weight: 1
      },
      borderLeft: {
        color: PulseThemeColors.UnFilteredGridHeader.backgroundColor,
        lineStyle: 'Continuous',
        weight: 1
      },
      borderRight: {
        color: PulseThemeColors.UnFilteredGridHeader.backgroundColor,
        lineStyle: 'Continuous',
        weight: 1
      },
      borderTop: {
        color: PulseThemeColors.UnFilteredGridHeader.backgroundColor,
        lineStyle: 'Continuous',
        weight: 1
      }
    }
  },
  {
    id: 'filteredColumn',
    alignment: {
      vertical: 'Center'
    },
    font: {
      bold: true,
      size: 12,
      color: PulseThemeColors.FilteredGridHeader.color
    },
    interior: {
      /** This color needs to match the actual style of the header (when it is filtered) AND the color on the border elements below */
      color: PulseThemeColors.FilteredGridHeader.backgroundColor,
      pattern: 'Solid',
      patternColor: undefined
    },
    borders: {
      borderBottom: {
        color: PulseThemeColors.FilteredGridHeader.backgroundColor,
        lineStyle: 'Continuous',
        weight: 1
      },
      borderLeft: {
        color: PulseThemeColors.FilteredGridHeader.backgroundColor,
        lineStyle: 'Continuous',
        weight: 1
      },
      borderRight: {
        color: PulseThemeColors.FilteredGridHeader.backgroundColor,
        lineStyle: 'Continuous',
        weight: 1
      },
      borderTop: {
        color: PulseThemeColors.FilteredGridHeader.backgroundColor,
        lineStyle: 'Continuous',
        weight: 1
      }
    }
  },
  {
    id: 'redCell',
    font: {
      color: PulseThemeColors.RedStatus.color
    },
    interior: {
      color: PulseThemeColors.RedStatus.backgroundColor,
      pattern: 'Solid',
      patternColor: undefined
    }
  },
  {
    id: 'yellowCell',
    font: {
      color: PulseThemeColors.YellowStatus.color
    },
    interior: {
      color: PulseThemeColors.YellowStatus.backgroundColor,
      pattern: 'Solid',
      patternColor: undefined
    }
  },
  {
    id: 'orangeCell',
    font: {
      color: PulseThemeColors.OrangeStatus.color
    },
    interior: {
      color: PulseThemeColors.OrangeStatus.backgroundColor,
      pattern: 'Solid',
      patternColor: undefined
    }
  }
];
